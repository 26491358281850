<template>
  <div>
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>ダッシュボード</span>
        <div class="export" v-show="!detailModal">
          <div @click="handleBasicExport()" class="export-title">
            <span>エクスポート</span>
          </div>
<!--          <div @click="changeExportActive" :class="exportActive ? 'active' : ''" class="export-list">
            <p>CSV</p>
            <p>PDF</p>
          </div>-->
        </div>
      </div>


      <div v-show="!detailModal">
      <div class="two">
        <div class="box intro">
          <div class="box-top">
            <p>エントリー</p>
            <div class="go-list" @click="toEntryLevel()">一覧へ</div>
          </div>
          <div class="box-content">
            <div class="left">
              <div class="top">
                <div>
                  <div class="item">
                    <p class="left-title">■累計エントリー数</p>
                    <p class="left-num">{{ data.entryTotalCnt }}</p>
                  </div>
                  <div class="item">
                    <p class="left-title">■{{data.lastYearName}}</p>
                    <p class="left-num">{{data.lastYearCnt}}</p>
                  </div>
                  <div class="item">
                    <p class="left-title">■{{data.thisYearName}}</p>
                    <p class="left-num">{{data.thisYearCnt}}</p>
                  </div>
                </div>
              </div>
            </div>
            <p class="time">{{ nowTime }}  更新</p>
          </div>


        </div>
        <div class="box intro">
          <div class="box-top">
            <p>内定率（累計）</p>
          </div>
          <div class="box-content">
            <div class="left">
              <div class="top">
                <div>
                  <div class="item">
                    <p class="left-title">■累計内定率</p>
                    <p class="left-num">
                      {{parseFloat((data.cadRatio* 100).toPrecision(12))}}
                      <span>%</span></p>
                  </div>
                  <div class="item">
                    <p class="left-title">■{{data.lastYearName}}</p>
                    <p class="left-num">
                      {{parseFloat((data.lastYearCadRatio* 100).toPrecision(12))}}
                      <span>%</span></p>
                  </div>
                  <div class="item">
                    <p class="left-title">■{{data.thisYearName}}</p>
                    <p class="left-num">
                      {{parseFloat((data.thisYearCadRatio* 100).toPrecision(12))}}
                      <span>%</span></p>
                  </div>
                </div>
              </div>
            </div>
            <p class="time">{{ nowTime }}  更新</p>
          </div>


        </div>

      </div>

      <div class="two">
        <div class="box user-num">
          <div class="box-top">
            <p>保存済ユーザー数</p>
          </div>
          <div class="box-content">
            <p class="title">貴社を保存したユーザーの人数（{{month}}月現在）</p>
            <div class="bottom">
              <div class="left">
                <p>■累計</p>
                <p>{{data.saveTotalCnt}}</p>
              </div>
              <div class="right">
                <p>■当月</p>
                <div>
                  <p>{{data.thisMonthCnt}}</p>
                  <div class="up" v-if="data.increaseLastMonthCnt>=0">
                    <img src="../../assets/images/index/up.png" />
                    <div>
                      <p>前月比</p>
                      <span>{{data.increaseLastMonthCnt}}人増</span>
                    </div>
                  </div>
                  <div class="up" v-if="data.increaseLastMonthCnt<0">
                    <img src="../../assets/images/index/down.png" />
                    <div>
                      <p>前月比</p>
                      <span>{{Math.abs(data.increaseLastMonthCnt)}}人減</span>
                    </div>
                  </div>
                </div>
                <p class="time">{{ nowTime }}  更新</p>
              </div>
            </div>
          </div>
        </div>

        <div class="box intro">
          <div class="box-top">
            <p>男女比（累計）</p>
          </div>
          <div class="box-content">
            <div class="right">
              <div id="gender-ratio"></div>
              <p class="time">{{ nowTime }}  更新</p>
            </div>

          </div>
        </div>
      </div>

      </div>

      <div class="box information">
        <div class="box-top">
          <p>information</p>
        </div>
        <div class="box-content">
          <div class="info-list">
            <div class="list-col" v-if="!detailModal">
              <a @click="toDetail(item)" v-for="(item,index) in data.infoList" :key="index"  class="item ellipsis" >{{item.drdate}}&nbsp;&nbsp;{{item.title}}</a>
            </div>

            <div class="detail" v-if="detailModal">

              <div class="date">{{detail.drdate}}</div>

              <div class="title">{{detail.title}}</div>

              <div class="img-div" v-if="detail.imageUrl != null && detail.imageUrl != ''">
                <a style="cursor:pointer;" :href="detail.linkUrl" target="_blank" v-if="detail.linkUrl != null && detail.linkUrl != ''">
                  <img :src="detail.imageUrl" class="imgUrl"/>
                </a>
                <a v-else>
                  <img :src="detail.imageUrl" class="imgUrl"/>
                </a>
              </div>

              <div class="content">{{detail.contents}}</div>

              <div class="btn green-btn" @click="goBack()">
                ダッシュボードへ戻る
              </div>

            </div>

          </div>
        </div>
      </div>


<!--      <div style="display: none;" class="box intro">
        <div class="box-top">
          <p>エントリー</p>
          <div class="go-list">一覧へ</div>
        </div>
        <div class="box-content">
          <div class="left">
            <div class="top">
              <p class="title">エントリー数</p>
              <div>
                <div class="item">
                  <p class="left-title">■累計エントリー数</p>
                  <p class="left-num">84</p>
                  <P class="left-desc">2024/12/23 13:12  更新</P>
                </div>
                <div class="item">
                  <p class="left-title">■2024年卒</p>
                  <p class="left-num">36</p>
                </div>
                <div class="item">
                  <p class="left-title">■2025年卒</p>
                  <p class="left-num">48</p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <p class="title">エントリー数</p>
              <p class="bottom-num">50<span>%</span></p>
              <p class="time">2024/12/23 13:12  更新</p>
            </div>
          </div>
          <div class="right">
            <p class="title">男女比（累計）</p>
            <div id="gender-ratio-2"></div>
            <p class="time">2024/12/23 13:12  更新</p>
          </div>
        </div>
      </div>
      <div class="two" style="display: none;">
        <div class="box user-num">
          <div class="box-top">
            <p>保存済ユーザー数</p>
          </div>
          <div class="box-content">
            <p class="title">貴社を保存したユーザーの人数（12月現在）</p>
            <div class="bottom">
              <div class="left">
                <p>累計</p>
                <p>28</p>
              </div>
              <div class="right">
                <p>当月</p>
                <div>
                  <p>5</p>
                  <div class="up">
                    <img src="../../assets/images/index/up.png" />
                    <div>
                      <p>前月比</p>
                      <span>5人增</span>
                    </div>
                  </div>
                </div>
                <p class="time">2024/12/23 13:12  更新</p>
              </div>
            </div>
          </div>
        </div>

        <div class="box view-num">
          <div class="box-top">
            <p>原稿閲覧数（月間）</p>
            <div class="date-list" :class="dateActive ? '' : 'cancel'" @click="changeDateIndex(-1)">
              <p>{{selectDate[selectDateIndex]}}</p>
              <img src="../../assets/images/index/arrow_down_two.png">

              <div class="list">
                <p class="item" @click.stop="changeDateIndex(index)" :class="index == selectDateIndex ? 'selected' : ''" v-for="(item, index) in selectDate" :key="index">{{item}}</p>
              </div>
            </div>
          </div>
          <div class="box-content">
            <div class="item">
              <div class="left">
                <p class="item-date ellipsis">2024/12/23 13:12  更新</p>
                <p class="item-title ellipsis">【24新卒】採用コンサル営業</p>
                <a href="#" class="item-url ellipsis">https://winc-careercareer.jp/jobDetail?id=366&cid=123</a>
              </div>
              <p class="title-num">プレビュー数</p>
              <p class="detail-num">1201</p>
            </div>
            <div class="item">
              <div class="left">
                <p class="item-date ellipsis">2024/12/23 13:12  更新</p>
                <p class="item-title ellipsis">【24新卒】採用コンサル営業</p>
                <a href="#" class="item-url ellipsis">https://winc-careercareer.jp/jobDetail?id=366&cid=123</a>
              </div>
              <p class="title-num">プレビュー数</p>
              <p class="detail-num">40</p>
            </div>
            <div class="item">
              <div class="left">
                <p class="item-date ellipsis">2024/12/23 13:12  更新</p>
                <p class="item-title ellipsis">【24新卒】採用コンサル営業</p>
                <a href="#" class="item-url ellipsis">https://winc-careercareer.jp/jobDetail?id=366&cid=123</a>
              </div>
              <p class="title-num">プレビュー数</p>
              <p class="detail-num">9</p>
            </div>
            <div class="item">
              <div class="left">
                <p class="item-date ellipsis">2024/12/23 13:12  更新</p>
                <p class="item-title ellipsis">【24新卒】採用コンサル営業</p>
                <a href="#" class="item-url ellipsis">https://winc-careercareer.jp/jobDetail?id=366&cid=123</a>
              </div>
              <p class="title-num">プレビュー数</p>
              <p class="detail-num">1201</p>
            </div>

          </div>
        </div>
      </div>
      <div style="display:none;" class="box join">
        <div class="box-top">
          <p>WinC Audition 参加</p>
        </div>
        <div class="box-content">
          <div class="left">
            <p class="title">点数分布</p>
            <div id="point-distribution"></div>
          </div>
          <div class="right">
            <p class="title">選考通過率</p>
            <div id="pass"></div>
          </div>
        </div>
      </div>-->

    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "index",

  data() {
    return {
      userInfo:{},
      month:(new Date().getMonth()+1),
      detailModal:false,
      detail:{},
      exportActive: false,

      selectDate: ['', '2023年4月', '2023年5月', '2023年6月', '2023年7月'],
      selectDateIndex: 0,
      dateActive: false,

      nowTime: '',

      data: {
        browseInfoList:[]
      },

      genderRatio: {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },

        tooltip: {
          trigger: 'item',
          formatter: '{b}'

        } /* 设置提示 */,
        legend: {
          orient: 'vertical',
          left: '68.5%',
          top: '30%'
        },


        color: ['#C5E5FD', '#FFD0D6', '#ebfbc7'],
        series: [
          {
            name: '男女比（累計）',
            type: 'pie',
            radius: '75%',
            center: ['41.4%', '50%'],
            data: [

            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0)'
              }
            },
            itemStyle : {
              normal : {
                label : {
                  show : false   //隐藏标示文字
                },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
          }
        ]
      },

      /*pointDistribution: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: '65.07%',
          top: '28%'
        },

        color: ['#C5E5FD', '#FFD0D6', '#F0FAF4'],

        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '90%',
            center: ['45.65%', '50%'],
            data: [
              { value: 62, name: '5点以上…62%' },
              { value: 31, name: '3点一上…31%' },
              { value: 7, name: '3点一下…7%' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0)'
              }
            },
            itemStyle : {
              normal : {
                label : {
                  show : false   //隐藏标示文字
                },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
          }
        ]
      },

      pass: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: '65.07%',
          top: '28%'
        },

        color: ['#C5E5FD', '#FFD0D6', '#F0FAF4'],

        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '90%',
            center: ['45.65%', '50%'],
            data: [
              { value: 62, name: '説明会エントリー…62%' },
              { value: 31, name: 'エントリー…31%' },
              { value: 7, name: '面接…7%' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0)'
              }
            },
            itemStyle : {
              normal : {
                label : {
                  show : false   //隐藏标示文字
                },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
          }
        ]
      },*/
    }
  },

  mounted() {
    this.getData();
    this.userInfo=JSON.parse(localStorage.USER_INFO);

   /* let pointDistribution = this.$echarts.init(document.getElementById('point-distribution'))
    pointDistribution.setOption(this.pointDistribution);

    let pass = this.$echarts.init(document.getElementById('pass'))
    pass.setOption(this.pass);*/
  },

  methods: {
    toEntryLevel(){
      this.$router.push("index/entryLevel");
    },
    goBack(){
      this.detailModal=false;
    },
    async toDetail(item){
      const userInfo =JSON.parse(localStorage.getItem("USER_INFO"));
      let res = await this.$axios({url: `/company/infoDetail`, data: {id: item.id,compId:userInfo.compId}, method: "post"});
      this.detail=res.data;
      this.detailModal=true;
    },
    async getData() {
      this.nowTime = this.formatTime();
      const token = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN);
      let res = await this.$axios({url: `/company/dashboard`, data: {token: token}, method: "post"});
      this.data = res.data;

      let genderRatio = this.$echarts.init(document.getElementById('gender-ratio'))
      this.genderRatio.series[0].data=
          [
            { value: this.data.maleRatio, name: '男性…'+Math.round(this.data.maleRatio*100)+'%' },
            { value: this.data.femaleRatio, name: '女性…'+Math.round(this.data.femaleRatio*100)+'%' },
            { value: this.data.otherRatio, name: '無回答…'+Math.round(this.data.otherRatio*100)+'%' }
          ];
      genderRatio.setOption(this.genderRatio);

      console.log(this.data)
    },

    initEcharts() {

    },

    formatTime() {
      //dataString是整数，否则要parseInt转换
      var time = new Date();
      var year = time.getFullYear();
      var month = time.getMonth()+1;
      var day = time.getDate();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      return year+'/'+(month<10?'0'+month:month)+'/'+(day<10?'0'+day:day)+' '+(hour<10?'0'+hour:hour)+':'+(minute<10?'0'+minute:minute)
      // return year+'-'+(month<10?'0'+month:month)+'-'+(day<10?'0'+day:day)+' '+(hour<10?'0'+hour:hour)+':'+(minute<10?'0'+minute:minute)+':'+(second<10?'0'+second:second)
    },

    handleBasicExport(){
      this.$confirm('ダッシュボード情報を一括ダウンロードしますか？', '', {
        confirmButtonText: 'はい',
        cancelButtonText: '取消',
        center: true
      }).then(() => {

          this.changeExportActive();

      })
    },
    exportExcel(res, fileName){
      if(res.type=="application/json"){ //no data to download
        const reader= new FileReader();
        reader.onload=function(){
          const {msg}= JSON.parse(reader.result);
          Message({
            showClose: true,
            message: msg,
            type: "error",
          });
        };
        reader.readAsText(res);
      }
      else {
        //console.log("res", res)
        const link = document.createElement('a')
        let blob = new Blob([res], {type: 'application/vnd.ms-excel'});
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName+ '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
   async changeExportActive() {

      let res = await this.$axios({url: `/company/dashboardExport`,
        responseType: 'blob',
        data: {
          token: this.userInfo.token
        }, method: "post"});

      this.exportExcel(res, 'dashboard');


    },

    changeDateIndex(index) {
      this.dateActive = !this.dateActive;
      console.log("sss")
      if(index != -1) {
        this.selectDateIndex =  index;
      }

    }
  }
}
</script>

<style scoped src="../../style/index.css">

</style>
